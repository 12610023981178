import React from "react";

class CardProject extends React.Component {
    renderContent() {
        return (
            <>
                <img src={this.props.image} className="w-full" alt="Project"/>
                <div className="p-15">
                    <div className="row mb-20">
                        <div className="col-md-6">
                            <h3 className="dark-pink font-18 m-0 bold">{this.props.title}</h3>
                            <p className="text-uppercase font-10">{this.props.type}</p>
                        </div>
                        <div className="col-md-6 text-center px-n5">
                            {this.props.tecnology === 'wordpress' && (
                                <span className="bg-blue-wordpress tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-wordpress bg-white mr-3"></i>
                                    Wordpress
                                </span>
                            )}
                            {this.props.tecnology === 'woocommerce' && (
                                <span className="bg-purple-woocommerce tag white mb-2 ml-8 mr-8">
                                    <i className="icon icon-woocommerce mr-1"></i>
                                    WooCommerce
                                </span>
                            )}
                            {this.props.tecnology === 'react' && (
                                <span className="bg-blue-reactjs tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-reactjs bg-white mr-3"></i>
                                    React
                                </span>
                            )}
                            {this.props.tecnology === 'ionic' && (
                                <span className="bg-blue-ionic tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-ionic bg-white mr-3"></i>
                                    Ionic
                                </span>
                            )}
                            {this.props.tecnology === 'html' && (
                                <span className="bg-red-html tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-html bg-white mr-3"></i>
                                    HTML
                                </span>
                            )}
                            {this.props.tecnology === 'angular' && (
                                <span className="bg-red-angular tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-angular bg-white mr-3"></i>
                                    Angular
                                </span>
                            )}
                            {this.props.tecnology === 'vue' && (
                                <span className="bg-green-vuejs tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-vuejs bg-white mr-3"></i>
                                    Vue
                                </span>
                            )}
                            {this.props.tecnology === 'shopify' && (
                                <span className="bg-green-shopify tag white mb-2 ml-10 mr-10">
                                    <i className="icon icon-shopify bg-white mr-3"></i>
                                    Shopify
                                </span>
                            )}
                            <p className="text-uppercase font-10">{this.props.developedReason}</p>
                        </div>
                    </div>
                    <p className="font-14 mb-10">{this.props.description}</p>
                </div>
            </>
        );
    }

    render() {
        return (
            <div className="h-100 d-block">
                <div className={(this.props.link ? "card-link":"no-link") + " card-project bg-white border-gray shadow dark-gray h-full rounded-small overflow-hidden dark-mode-bg dark-mode-white"}>
                    {this.props.link ? (
                        <a href={this.props.link} target="_blank" rel="noopener noreferrer">
                            {this.renderContent()}
                        </a>
                    ) : (
                        this.renderContent()
                    )}
                </div>
            </div>
        );
    }
}

export default CardProject;
