import React, {useContext, useEffect} from "react";
import GlobalContext from "../context/GlobalContext";
import Header from '../layouts/Header';
import CardProject from '../components/CardProject';
import { Link } from "react-router-dom";

import projectLegispedia from "../img/project-legispedia.jpg";
import projectDigitalks from "../img/project-digitalks.jpg";
import Tag from "../components/Tag";

import SwiperCore, { Pagination, Autoplay } from 'swiper';
SwiperCore.use([Pagination, Autoplay]);

const Home = () => {
    const {language, setLanguage} = useContext(GlobalContext);
    const isMobile = window.innerWidth < 992;
    
    useEffect(() => {
        document.body.classList.remove('hide-sidebar-mobile');
    },[]); 

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-9 offset-md-3 col-index">
                    <Header />

                    <div className="content-div p-30 col-md-12">
                        <div className="row">
                            <div className="col-md-12">
                                <span className="w-full block bg-pink m-0 rounded-top-small page-title"></span>
                                    <div className="bg-editor-gray white p-15 mb-30 rounded-bottom-small mobile-text-center">
                                        <div className="row">
                                            <div className="col-md-11">
                                                {language == 'en' ?
                                                    <h1 className="pink font-comfortaa font-28 m-0">Hi!</h1>
                                                :''}
                                                {language == 'pt' ?
                                                    <h1 className="pink font-comfortaa font-28 m-0">Olá!</h1>
                                                :''}
                                                {language == 'en' ?
                                                    <p className="mt-10 mb-10">
                                                        Throughout my career, I have done many projects transforming layouts into code. I did institutional websites, landing pages and ecommerces focused on <strong>WordPress</strong> and <strong>Shopify</strong> platforms.
                                                        <br /><br />
                                                        After having contact with some libraries, I really liked <strong>React</strong> and decided to dedicate myself to studying it and now I am looking for an opportunity to apply my knowledge.
                                                    </p>
                                                :''}
                                                {language == 'pt' ?
                                                    <p className="mt-10 mb-10">
                                                        Ao longo da minha carreira, fiz vários projetos transformando layouts em código. Fiz sites institucionais, landing pages e e-commerces focados nas plataformas em <strong>WordPress</strong> e <strong>Shopify</strong>.
                                                        <br /><br />
                                                        Depois de ter contato com algumas bibliotecas, gostei muito de <strong>React</strong> e decidi me dedicar aos estudos. Agora estou buscando uma oportunidade para aplicar meus conhecimentos.
                                                    </p>
                                                :''}
                                            </div>
                                        </div>
                                    </div>
                            </div>
                            <div className="col-md-4">
                                <h2 className="font-18 dark-pink mb-0 mt-15 bold mobile-text-center">
                                    {language == 'en' ?
                                        <>Skills</>
                                    :''}
                                    {language == 'pt' ?
                                        <>Conhecimento</>
                                    :''}
                                </h2>
                                
                                <div className="row g-2 mt-20 mb-20">
                                    <div className="col-6 pr-1">
                                        <ul>
                                            <Tag name="HTML" background="#fc490b" icon="html" link="html"/>
                                            <Tag name="CSS" background="#0b74b8" icon="html" link="html"/>
                                            <Tag name="JavaScript" background="#dab92c" icon="javascript"/>
                                            <Tag name="JQuery" background="#0769ad" icon="jquery"/>
                                            <Tag name="Git" background="#f03c2e" icon="git"/>

                                            {/*
                                            <Tag name="English" translation="Inglês" background="#16164c" icon="usa-flag"/>
                                            <Tag name="Portuguese" translation="Português" background="#448f49" icon="brazil-flag"/>
                                            <Tag name="Layout to HTML" translation="Layout para HTML" background="#470137" icon="file"/>
                                            <Tag name="Responsiveness" translation="Responsividade" background="#40939a" icon="responsive"/>
                                            <Tag name="SEO Markup" background="#470485" icon="seo"/>
                                            */}
                                        </ul>
                                    </div>
                                    <div className="col-6 pl-1">
                                        <ul>
                                            <Tag name="React" background="#00ccff" icon="reactjs" link="react"/>
                                            <Tag name="Next.js" background="#000000" icon="next"/>
                                            <Tag name="React Native" background="#20232a" icon="reactjs"/>

                                            {/*
                                            <Tag name="TypeScript" background="#3278c7" icon="typescript"/>
                                            <Tag name="Redux" background="#000000" icon="reactjs"/>
                                            <Tag name="APIs Restful" background="#000000" icon="reactjs"/>
                                            <Tag name="Testes Unitários e de Integração" background="#000000" icon="reactjs"/>
                                            <Tag name="Metodologias ágeis (Scrum, Kanban)" background="#000000" icon="reactjs"/>
                                            */}

                                            {/*
                                            <Tag name="AngularJS" background="#e23237" icon="angular"/>
                                            <Tag name="VueJS" background="#4fc08d" icon="vuejs"/>
                                            <Tag name="Ionic" background="#3880ff" icon="ionic"/>
                                            */}
                                            
                                            <Tag name="Wordpress" background="#075b7f" icon="wordpress" link="wordpress"/>
                                            <Tag name="Shopify" background="#5e8d3e" icon="shopify" link="shopify"/>

                                            {/*
                                            <Tag name="BigCommerce" background="#000000" icon="bigcommerce"/>
                                            <Tag name="WooCommerce" background="#7f54b3" icon="woocommerce"/>
                                            */}
                                        </ul>
                                    </div>
                                </div>


                                <Link to="/experience" className="shrink-border mb-30 mt-10 w-full text-center font-16">
                                    {language == 'en' ?
                                        <>Work experience</>
                                    :''}
                                    {language == 'pt' ?
                                        <>Experiência de trabalho</>
                                    :''}
                                </Link>

                                <div className="footer-note dark-pink font-14">
                                    {language == 'en' ?
                                    <>/* Site developed using React */</>
                                    :''}
                                    {language == 'pt' ?
                                    <>/* Site desenvolvido em React */</>
                                    :''}
                                </div>
                            </div>
                            <div className="col-md-8">
                                {language == 'en' ?
                                    <div className="text-with-button">
                                        <h2 className="font-18 dark-pink mb-20 bold mobile-text-center">Featured Projects</h2>
                                        <Link to="/projects/all" className="text-center btn-projects">
                                            <a className="shrink-border font-16 w-300">See all the projects</a>
                                        </Link>
                                    </div>
                                :''}
                                {language == 'pt' ?
                                    <div className="text-with-button">
                                        <h2 className="font-18 dark-pink mb-20 bold mobile-text-center">Projetos em Destaque</h2>
                                        <Link to="/projects/all" className="text-center btn-projects">
                                            <a className="shrink-border font-16 w-300">Ver Todos os projetos</a>
                                        </Link>
                                    </div>
                                :''}

                                <div className="row">
                                    <div className="col-md-6 mb-20">
                                        {language == 'en' ?
                                        <CardProject
                                            image={projectLegispedia}
                                            title="Legispedia"
                                            type="Web App"
                                            tecnology="react"
                                            developedReason=""
                                            description="Legispedia is a tool to study and understand Law."
                                            link="http://andressabertolini.com/project/legispedia"
                                            className="h-full"
                                        />
                                        :''}
                                        {language == 'pt' ?
                                        <CardProject
                                            image={projectLegispedia}
                                            title="Legispedia"
                                            type="Aplicativo Web"
                                            tecnology="react"
                                            developedReason=""
                                            description="A Legispedia é uma ferramenta estudar e entender o Direito."
                                            link="http://andressabertolini.com/project/legispedia"
                                            className="h-full"
                                        />
                                        :''}
                                    </div>
                                    <div className="col-md-6 mb-20">
                                        {language == 'en' ?
                                        <CardProject
                                            image={projectDigitalks}
                                            title="Digitalks"
                                            type="Interactive Menu"
                                            tecnology="html"
                                            developedReason=""
                                            description="Scroll navigation menu lobby to access Digitalks course content."
                                            link="http://andressabertolini.com/project/digitalks"
                                            className="h-full"
                                        />
                                        :''}
                                        {language == 'pt' ?
                                        <CardProject
                                            image={projectDigitalks}
                                            title="Digitalks"
                                            type="Menu Interativo"
                                            tecnology="html"
                                            developedReason=""
                                            description="Menu de navegação scroll em lobby para acessar conteúdo do curso Digitalks."
                                            link="http://andressabertolini.com/project/digitalks"
                                            className="h-full"
                                        />
                                        :''}
                                    </div>
                                </div>

                                
                                <div className="col-md-12 mb-20 btn-projects-desktop">
                                    {language == 'en' ?
                                    <Link to="/projects/all">
                                        <a className="shrink-border mb-30 mt-10 w-full text-center font-16">See all the projects</a>
                                    </Link>
                                    :''}
                                    {language == 'pt' ?
                                        <Link to="/projects/all">
                                            <a className="shrink-border mb-30 mt-10 w-full text-center font-16">Ver Todos os projetos</a>
                                        </Link>
                                    :''}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );

}

export default Home;